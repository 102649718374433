
























































































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import Loading from "@/components/Loading.vue";

export default Vue.extend({
  components: { Loading },
  data() {
    return {
      self: this,
      loading: false,
      items: [],
      chargingStation: null,
    };
  },
  methods: {
    async initialize() {
      this.loading = true;
      {
        const $route = this.$route;
        if ($route.query.chargingStationId) {
          const chargingStation = await coreApiClient.call(
            "chargingStations",
            "findById",
            {
              id: $route.query.chargingStationId,
            }
          );
          this.chargingStation = chargingStation;
        }
      }
      {
        const filters = [];
        if (this.$route.query.id) {
          filters.push({
            key: "_id",
            operator: "equal_to_id",
            value: this.$route.query.id,
          });
        } else {
          filters.push({
            key: "stopTime",
            operator: "greater_than",
            value: 0,
          });
        }
        const { items } = await coreApiClient.call(
          "chargingSessions",
          "findAll",
          {
            payload: JSON.stringify({
              limit: -1,
              filters,
              orderBy: "startTime",
              orderType: "desc",
            }),
          }
        );
        this.items = items;
      }
      this.loading = false;
    },
  },
  created() {
    this.initialize();
  },
});
